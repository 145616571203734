import axios from "@/api/axios"; // 引用axios

export const meetingList = (params) => {
    return axios({
        url: "/api/yunWu/queryMeetingList",
        method: "post",
        params: params,
    });
};

export const queryUser = (params) => {
    return axios({
        url: "/api/yunWu/queryUser",
        method: "post",
        params: params,
    });
};

export const queryMeeting = (params) => {
    return axios({
        url: "/api/yunWu/queryMeeting/" + params,
        method: "post",
    });
};

export const updateMeeting = (confId, data) => {
    return axios({
        url: "/api/yunWu/updateMeeting/" + confId,
        method: "post",
        data: data,
    });
};

export const createMeeting = (params) => {
    return axios({
        url: "/api/yunWu/createMeeting",
        method: "post",
        data: params,
    });
};

export const deleteMeeting = (confId) => {
    return axios({
        url: "/api/yunWu/deleteMeeting/" + confId,
        method: "post",
    });
};
