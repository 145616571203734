<template>
  <div>
    <Searchbar @emit-search="onSearch" @reset-form="resetForm">
      <template #SEARCH>
        <el-col :span="8">
          <el-form-item label="起始结束时间">
            <el-date-picker
                style="height: 100%;"
                format="YYYY-MM-DD HH:mm"
                value-format="YYYY-MM-DD HH:mm"
                v-model="time"
                type="datetimerange"
                range-separator="至"
                start-placeholder="开始日期"
                end-placeholder="结束日期">
            </el-date-picker>
          </el-form-item>
        </el-col>
      </template>
      <template #BTN>
        <el-button @click="dialogFormVisible = true; title = '新增'" class="searchBtn">新增</el-button>
      </template>
    </Searchbar>

    <basicTable
        :tableData="tableData"
        :attrList="attrList"
        :page="page.current"
        :limit="page.limit"
        @currentChange="handleCurrentChange"
        @sizeChange="handleSizeChange"
        :total="page.total">
    </basicTable>

    <el-dialog
        append-to-body
        v-model="dialogFormVisible"
        :title="title"
        :close-on-click-modal="false"
        :before-close="handleClose"
    >
      <el-form
          label-position="right"
          :model="form"
          size="small"
          label-width="150px"
          ref="form"
          :rules="rules"
      >
        <el-row :gutter="20">
          <el-col :span="12">
            <el-form-item label="会议主题：" prop="subject" :rules="[{ required: true, message: '请输入 会议主题' }]">
              <el-input :disabled="isedit" v-model="form.subject" placeholder="请输入 会议主题" autocomplete="off" />
            </el-form-item>
          </el-col>

          <el-col :span="12">
            <el-form-item label="主持人：" prop="hostId" :rules="[{ required: true, message: '请选择 主持人' }]">
              <el-select :disabled="isedit" filterable v-model="form.hostId" placeholder="请选择">
                <el-option v-for="item in queryUser" :key="item.userId" :label="item.name" :value="item.userId"></el-option>
              </el-select>
            </el-form-item>
          </el-col>

          <el-col :span="12">
            <el-form-item label="联席主持人：" prop="assistantInfos">
              <el-select :disabled="isedit" filterable multiple v-model="form.assistantInfos" placeholder="请选择">
                <el-option v-for="item in queryUser" :key="item.userId" :label="item.name" :value="item.userId"></el-option>
              </el-select>
            </el-form-item>
          </el-col>

          <el-col :span="12">
            <el-form-item label="参会人员：" prop="inviteeIds">
              <el-select :disabled="isedit" filterable multiple v-model="form.inviteeIds" placeholder="请选择">
                <el-option v-for="item in queryUser" :key="item.userId" :label="item.name" :value="item.userId"></el-option>
              </el-select>
            </el-form-item>
          </el-col>

          <el-col :span="12">
            <el-form-item label="会议密码：" prop="password">
              <div style="display: flex;align-items: center;justify-content: center;height: 100%;">
                <el-switch :disabled="isedit" @change="form.password = ''" v-model="isPwd" size="large" active-text="是" inactive-text="否"/>
                <el-input :disabled="isedit" v-if="isPwd" clearable v-model="form.password" placeholder="请输入 会议密码" autocomplete="off"></el-input>
              </div>
            </el-form-item>
          </el-col>

          <el-col :span="12">
            <el-form-item label="预约开始时间：" prop="scheduleTime">
              <el-date-picker :disabled="isedit" v-model="form.scheduleTime" format="YYYY-MM-DD HH:mm" value-format="YYYY-MM-DD HH:mm" type="datetime" placeholder="预约开始时间"></el-date-picker>
            </el-form-item>
          </el-col>

          <el-col :span="12">
            <el-form-item label="持续时间：" prop="scheduleMin">
              <div style="display: flex;align-items: center;justify-content: center">
                <el-select :disabled="isedit" v-model="form.scheduleH">
                  <el-option v-for="item in scheduleH" :key="item.value" :label="item.label" :value="item.value"></el-option>
                </el-select>

                <el-select :disabled="isedit" v-model="form.scheduleMin">
                  <el-option v-for="item in scheduleMin" :key="item.value" :label="item.label" :value="item.value"></el-option>
                </el-select>
              </div>
            </el-form-item>
          </el-col>

          <el-col :span="12">
            <el-form-item label="周期类型：" prop="periodType">
              <el-select :disabled="isedit" v-model="form.periodType">
                <el-option v-for="item in periodType" :key="item.value" :label="item.label" :value="item.value"></el-option>
              </el-select>
            </el-form-item>

            <div class="period__div" v-if="form.periodType">
              <span>结束重复：</span>
              <el-select :disabled="isedit" style="width: 130px !important;" v-model="form.periodEndx">
                <el-option v-for="item in periodEndType" :key="item.value" :label="item.label" :value="item.value"></el-option>
              </el-select>

              <div v-if="form.periodEndx != 1" style="width: 150px !important;margin-left: 10px">
                <el-date-picker :disabled="isedit" placeholder="请选择 结束时间" v-model="form.periodEndTime" format="YYYY-MM-DD" value-format="YYYY-MM-DD" type="date"></el-date-picker>
              </div>

            </div>
          </el-col>

          <el-col :span="12">
            <el-form-item label="允许参会权限：" prop="allowJoin">
              <el-select :disabled="isedit" v-model="form.allowJoin">
                <el-option v-for="item in allowJoin" :key="item.value" :label="item.label" :value="item.value"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <template #footer>
        <div class="dialog-footer" v-show="!isedit">
          <el-button type="primary" size="small" @click="onSend">提交</el-button>
        </div>
      </template>
    </el-dialog>
  </div>
</template>

<script>
import Searchbar from '@/components/Searchbar';
import { meetingList, queryUser, createMeeting, queryMeeting, updateMeeting, deleteMeeting } from "@/api/yuwu/meeting";
import { changeTime, formatDayTime, getNowTime } from "../../util/date";
import {ElMessage, ElMessageBox} from "element-plus";
export default {
  components:{
    Searchbar
  },
  data(){
    const scheduleTime = (rule, value, callback) => {
      if (!value) {
        callback(new Error('开始时间不能为空'))
      }else{
        let nowTime = Math.ceil(new Date().getTime() / 1000);
        let valueTime =  new Date(value).getTime()/ 1000;
        if((valueTime - nowTime) / 60 < 15){
          callback(new Error('开始时间不能小于当前时间'))
        }else {
          this.form.scheduleEndTime = Math.ceil(valueTime + (this.form.scheduleH * 60 + this.form.scheduleMin) * 60);
          callback()
        }
      }
    }

    const scheduleMin = (rule, value, callback) => {
      if (this.form.scheduleH == 0 && value == 0) {
        callback(new Error('持续时间不能为空'))
      }else {
        let sum = this.form.scheduleH * 60 + value;
        this.form.scheduleEndTime = Math.ceil(new Date(this.form.scheduleTime).getTime() / 1000 + sum * 60);
        callback();
      }
    }

    const passWord = (rule, value, callback) => {
      if (!value && !this.isPwd) {
        callback()
      }else if(this.isPwd && value && value.length >= 4 && value.length <= 6){
        callback();
      }else {
        callback(new Error('密码长度位4-6位'))
      }
    }

    return{
      isedit:false,
      title:"",
      allowJoin:[
        {
          value: 0,
          label: "所有用户"
        },
        {
          value: 1,
          label: "企业内用户"
        },
        {
          value: 2,
          label: "邀请对象"
        },
      ],
      periodEndType:[
        {
          value: 1,
          label: "不结束"
        },
        {
          value: 2,
          label: "结束于某天"
        },
        // {
        //   value: 3,
        //   label: "限制次数"
        // },
      ],
      rules:{
        scheduleTime : [{ validator: scheduleTime, required: true}],
        scheduleMin : [{ validator: scheduleMin, required: true}],
        password:[{ validator: passWord }]
      },
      isPwd: false,
      dialogFormVisible: false,
      form: {
        scheduleH: 1,
        scheduleMin: 0,
        periodType: 0,
        periodEndx: 2,
        allowJoin: 0,
      },
      searchForm:{},
      scheduleH:[],
      scheduleMin:[
        {
          value: 0,
          label: "0分钟"
        },
        {
          value: 15,
          label: "15分钟"
        },
        {
          value: 30,
          label: "30分钟"
        },
        {
          value: 45,
          label: "45分钟"
        },
      ],
      periodType:[
        {
          value: 0,
          label: "非周期会议"
        },
        {
          value: 1,
          label: "每天"
        },
        {
          value: 2,
          label: "每个工作日"
        },
        {
          value: 3,
          label: "每周"
        },
        {
          value: 4,
          label: "每两周"
        },
        {
          value: 5,
          label: "每月"
        },
      ],
      attrList:[
        {
          prop: "confId",
          label: "会议号",
          render: (row) => {
            return <div>{row.confId}</div>
          }
        },
        {
          prop: "subject",
          width: 300,
          label: "会议主题",
          render: (row) =>{
            const op = new Map([
              [0, '非周期会议'],
              [1, '每天'],
              [2, '每个工作日'],
              [3, '每周'],
              [4, '每两周'],
              [5, '每月']
            ])
            return <div>{row.subject} <el-tag type="success">{op.get(row.periodType)}</el-tag></div>
          },
        },
        {
          prop: "scheduleTime",
          label: "预约开始时间",
          render: (row) => <div>{changeTime(row.scheduleTime)}</div>,
        },
        {
          prop: "scheduleEndTime",
          label: "预约结束时间",
          render: (row) => <div>{changeTime(row.scheduleEndTime)}</div>,
        },
        {
          prop: "hostName",
          label: "主持人",
          render: (row) => <div>{row.hostName}</div>,
        },
        {
          prop: "status",
          label: "会议状态",
          render: (row) => <div>{ row.status ? '进行中' : '未开始' }</div>,
        },
        {
          prop: "",
          label: "操作",
          width: 320,
          render: (row) =><div>
            <el-button type="text" icon="Position" onClick={()=>this.into(row)}>
              加入
            </el-button>
            <el-button type="text" icon="Share" onClick={()=>this.copy(row)}>
              复制入会信息
            </el-button>
            <el-button type="text" icon="Edit" onClick={()=>this.editData(row)}>
              { row.status ? '查看' : '编辑' }
            </el-button>
            <el-button type="text" icon="Delete" onClick={()=>this.delData(row)}>
              { row.status ? '结束' : '删除' }
            </el-button>
          </div>,
        },
      ],
      tableData: [],
      time: [],
      page: {
        current: 1,
        limit: 10,
        total: 0,
      },
      queryUser:[],
    }
  },
  watch:{
    // "form.periodType"(n, o){
    //
    // },
  },
  mounted() {
    for(let i=0,len=24;i<len;i++){
      this.scheduleH.push({
        value: i,
        label: i + '小时'
      })
    }
    //初始化开始时间 当前时间基础上往后推了15分钟
    this.form.scheduleTime = changeTime(new Date(`${formatDayTime(new Date())} ${getNowTime()}`).getTime() / 1000 + 16 * 60);
    this.form.scheduleEndTime = Math.ceil(new Date(this.form.scheduleTime).getTime() / 1000 + (this.form.scheduleH * 60 + this.form.scheduleMin) * 60);
  },
  methods:{
    into(row){
      window.open(row.publicUrl, '_blank');
    },

    copy(row){
      queryMeeting(row.confId).then(res=>{
        let data = res.data.data.confDetail;
        var save = function (e) {
          //设置需要复制模板的内容
          e.clipboardData.setData('text/plain', `${data.hostName}邀请您参加会议\n会议主题：${data.subject}\n会议时间：${changeTime(data.orderConfig.scheduleTime)}\n\n点击链接参加会议：${data.publicUrl}\n\n会议号：${data.confId}\n会议密码：${data.password || '无'}`);
          //阻止默认行为
          e.preventDefault();
        }
        // h5监听copy事件，调用save函数保存到模板中
        document.addEventListener('copy',save);
        // 调用右键复制功能
        document.execCommand('copy');
        //移除copy事件
        document.removeEventListener('copy',save);
        ElMessage({
          message: '复制成功',
          type: 'success',
        })
      })

    },


    onSearch(){
      this.page.current = 1;
      if(this.time){
        this.searchForm.startTime = new Date(this.time[0]).getTime() / 1000;
        this.searchForm.endTime = new Date(this.time[1]).getTime() / 1000;
      }
      this.onLoad();
    },

    resetForm() {
      this.time = [];
      this.searchForm = {};
      this.page.current = 1;
      this.onLoad();
    },

    handleCurrentChange(currentPage) {
      this.page.current = currentPage;
      this.onLoad();
    },

    handleSizeChange(pageSize) {
      this.page.limit = pageSize;
      this.onLoad();
    },

    initData(){
      this.form = {
        scheduleH: 1,
        scheduleMin: 0,
        periodType: 0,
        periodEndx: 2,
        allowJoin: 0,
        scheduleTime: changeTime(new Date(`${formatDayTime(new Date())} ${getNowTime()}`).getTime() / 1000 + 16 * 60),
        scheduleEndTime: Math.ceil(new Date(this.form.scheduleTime).getTime() / 1000 + (this.form.scheduleH * 60 + this.form.scheduleMin) * 60)
      }
    },

    handleClose(done){
      this.initData();
      this.isedit = false;
      this.$refs['form'].resetFields();
      done()
    },

    delData(row){
      ElMessageBox.confirm('确认此操作?', '提示', { confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'Warning',
      }).then(() => {
        deleteMeeting(row.confId).then(res=>{
          ElMessage({message: '操作成功!', type: 'success'});
          this.page.current = 1;
          this.page.limit = 10;
          this.onLoad();
        })
      }).catch(() => {})
    },

    editData(row){
      queryMeeting(row.confId).then(res=> {
        let data = res.data.data.confDetail;

        this.$nextTick(()=>{
          this.form = data;
          this.form.assistantInfos =  data.assistantInfos.map(item=>item.inviteeId);
          this.form.inviteeIds = data.inviteeInfos.map(item=>item.inviteeId);
          this.form.periodType = data.orderConfig.periodType;
          this.form.allowJoin = data.attributes.allowJoin;
          this.form.scheduleTime = changeTime(data.orderConfig.scheduleTime);
          this.form.scheduleH = 1;
          this.form.scheduleMin = 0;
          data.password ? this.isPwd = true : this.isPwd = false;
          if(data.orderConfig.periodEndTime == -1){
            this.form.periodEndx = 1;
            this.form.periodEndTime = '';
          }else{
            this.form.periodEndx = 2;
            this.form.periodEndTime = formatDayTime(data.orderConfig.periodEndTime * 1000);
          }
          this.dialogFormVisible = true;
          if(row.status){
            this.title = '查看';
            this.isedit = true;
          }else{
            this.title = '编辑';
            this.isedit = false;
          }

        })
      })
    },

    onSend(){
      this.$refs['form'].validate((valid) => {
        if (valid) {
          let { scheduleTime, scheduleEndTime, subject, hostId, assistantInfos, inviteeIds, password, allowJoin, periodType, periodEndTime, periodEndx, confId } = this.form;
          let obj = {
            subject,
            scheduleTime: new Date(scheduleTime).getTime() / 1000,
            periodEndTime: ( !periodType || periodEndx == 1 ) ? -1 : new Date(periodEndTime).getTime() / 1000,
            scheduleEndTime,
            hostId,
            assistantInfos,
            inviteeIds,
            password,
            allowJoin,
            periodType
          }
          if(confId){
            updateMeeting(confId, obj).then(res=>{
              ElMessage({message: '操作成功!', type: 'success'});
              this.initData();
              this.$refs['form'].resetFields();
              this.page.current = 1;
              this.page.limit = 10;
              this.dialogFormVisible = false;
              this.onLoad();
            })
          }else{
            createMeeting( obj ).then(res=>{
              if(res.data.code.status == 1) {
                ElMessage({message: '操作成功!', type: 'success'});
                this.initData();
                this.$refs['form'].resetFields();
                this.page.current = 1;
                this.page.limit = 10;
                this.dialogFormVisible = false;
                this.onLoad();
              }
            })
          }

        } else {
          return false;
        }
      });
    },

    onLoad(){
      meetingList({limit:this.page.limit, page:this.page.current, ...this.searchForm}).then(res=>{
        this.tableData = res.data.data.records;
        this.page.total = res.data.data.total;
      })
    }
  },
  created() {
    this.onLoad();

    queryUser().then(async res=>{
      let total = res.data.data.total;
      let current = 100;
      for(let i=0,len=Math.ceil(total / current);i<len;i++){
        let r = await queryUser({ current:1, size: 100 })
        this.queryUser.push(...r.data.data.records)
      }
    })
  }
}
</script>

<style lang="less" scoped>
.period__div{
  margin-left: 80px;
  display:flex;
  align-items: center;
}
</style>